import { Maybe } from '@/types';

export const getInitials = (fullName?: string): string =>
  fullName
    ? fullName
        .split(' ')
        .map((name) => name[0].toUpperCase())
        .join('')
    : '';

export interface UserPreview {
  email: string;
  fullName: string;
  phone: Maybe<string>;
  evId: string;
  picture?: string;
}

export interface MinimalUser extends UserPreview {
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  identityProvider: Maybe<string>;
}

export type StoreField =
  | 'build'
  | 'model_level_page'
  | 'listing'
  | 'incomplete_build';

export type SavedVehicles = Record<StoreField, Record<string, RawSavedVehicle>>;

export interface IndividualSavedVehicleProps {
  isSaved: boolean;
}

export interface SavedVehiclesListProps {
  savedVehicles: SavedVehicles | null;
}

export interface RawSavedVehicle {
  id: string;
  price: string;
  year: number;
  make: string;
  model: string;
  link: string;
  image: string;
  contact_dealership?: boolean;
  created_at: string;
}

interface BaseSavedVehicle {
  year: number;
  make: string;
  image: string;
  model: string;
  trim?: string;
  dealerName?: string;
  price: string;
}

export interface SaveVehicleRequest extends BaseSavedVehicle {
  id: string | number;
}
export interface MinimalSavedVehicle extends BaseSavedVehicle {
  id: string;
}

export interface SavedVehicle extends MinimalSavedVehicle {
  expired: boolean;
  contactedDealer: boolean;
  storeField: StoreField;
}

// TODO: MVB-1103 Update with types from Amplify v6 once we upgrade
export interface MinimalCognitoUser {
  username: string;
  attributes: {
    email: string;
    phone_number: string;
    sub: string;
    /** Is the user's first name and last name or their first name by default. */
    given_name?: string;
    /** The user's first name. */
    name?: string;
    family_name?: string;
    identities: { providerName: string }[] | null;
  };
}
