import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import Link, { LinkVariants } from '@/components/atoms/legacy/Link';
import LegacyTextButton from '@/components/atoms/legacy/TextButton';
import Logo from '@/components/molecules/Logo';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import { Component } from '@/types/interfaces';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';
import React from 'react';
import FooterSocialIconGroup from '../FooterSocialIconGroup/FooterSocialIconGroup';
import { footerLinks } from '../FunnelFooter/FunnelFooter';

interface GeneralFooterLinksProps {
  subLinks: {
    text: string;
    href: string;
    analyticsEvent?: AnalyticsEvent;
  }[];
  sectionHeader: string;
}

const searchFooterLinks = [
  {
    text: 'New',
    href: '/search/condition/New',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.inventoryHomePageButton,
      pageTarget: '/search',
    },
  },
  {
    text: 'Used',
    href: '/search/condition/Used',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.inventoryHomePageButton,
      pageTarget: '/search',
    },
  },
  {
    text: 'Custom order',
    href: '/build',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.customOrderHomePageButton,
      pageTarget: '/build',
    },
  },
];

const discoverFooterLinks = [
  {
    text: 'Compare EVs',
    href: '/compare',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.compareEVHomePageButton,
      pageTarget: '/compare',
    },
  },
  {
    text: 'News & Reviews',
    href: '/news',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.newsHomePageButton,
      pageTarget: '/news',
    },
  },
  {
    text: 'FAQs',
    href: '/faq',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.frequrentlyAskedQuestionsButton,
      pageTarget: '/faq',
    },
  },
];

const partnerFooterLinks = [
  {
    text: 'Dealers',
    href: '/partner',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.partnerHomePageButton,
      pageTarget: '/partner',
    },
  },
  {
    text: 'OEMs',
    href: '/partner',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.partnerHomePageButton,
      pageTarget: '/partner',
    },
  },
  {
    text: 'Contact sales',
    href: 'mailto:hi@ev.com',
  },
];

const GeneralFooterLinkComponent: React.FC<GeneralFooterLinksProps> = ({
  subLinks,
  sectionHeader,
}) => {
  return (
    <section className="mr-[40px] m:w-full l:w-[160px]">
      <Heading
        className="mb-l whitespace-nowrap text-h3SemiBold text-white l:text-h4SemiBold"
        level={HeadingLevels.H3}
      >
        {sectionHeader}
      </Heading>
      <nav className="flex flex-col gap-s">
        {subLinks.map((link) => (
          <Link
            className="text-body2Light text-white hover:text-body2Regular hover:text-brandElectric"
            key={link.text}
            href={link.href}
            variant={LinkVariants.Default}
            analyticsEvent={link.analyticsEvent}
          >
            {link.text}
          </Link>
        ))}
      </nav>
    </section>
  );
};

const searchFooterLinksSection = (
  <GeneralFooterLinkComponent
    subLinks={searchFooterLinks}
    sectionHeader="Search"
  />
);

const discoverFooterLinksSection = (
  <GeneralFooterLinkComponent
    subLinks={discoverFooterLinks}
    sectionHeader="Discover"
  />
);

const partnerFooterLinksSection = (
  <GeneralFooterLinkComponent
    subLinks={partnerFooterLinks}
    sectionHeader="Partner with us"
  />
);

export const Footer: React.FC<Component> = ({ className = '', ...props }) => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={`relative py-[60px] text-white ${className}`} {...props}>
      <section className="mb-[40px] m:mb-[110px] m:flex m:flex-wrap l:flex-nowrap l:justify-between">
        <section className="flex m:basis-full m:justify-between l:basis-1/3 l:flex-col l:justify-start">
          <Link className="mb-[40px]" href="/" aria-label="home">
            <Logo className="relative w-[80px] m:w-[115px]" reverse />
          </Link>
          <section className="hidden space-x-[20px] m:flex">
            <FooterSocialIconGroup />
          </section>
        </section>
        <section className="l:basis-none mb-[40px] flex flex-grow flex-col gap-xl m:mb-[60px] m:basis-full m:flex-row m:gap-0 l:mb-0">
          {searchFooterLinksSection}
          {discoverFooterLinksSection}
          {partnerFooterLinksSection}
        </section>
        <section className="flex w-full flex-col justify-between gap-l border-t-[1px] border-t-neutral-600 pt-xl m:flex-row l:w-auto l:flex-col l:border-l-[1px] l:border-t-0 l:border-l-neutral-600 l:pl-[40px] l:pt-0">
          <section className="flex flex-col gap-l">
            <Heading
              className="text-h3SemiBold text-white m:text-h4SemiBold"
              level={HeadingLevels.H3}
            >
              Contact us
            </Heading>
            <div className="text-body1Light">Email hi@ev.com</div>
          </section>
          <section>
            <Button
              aria-label="Send us feedback"
              variant={ButtonVariants.Secondary}
              theme="dark"
              size="small"
              icon={{
                icon: <ChatBubbleBottomCenterTextIcon className="h-xl w-xl" />,
                position: 'left',
              }}
              onClick={() =>
                window.open(
                  'https://my8by23bgby.typeform.com/to/Gg3XiMYY',
                  '_blank'
                )
              }
            >
              Send us feedback
            </Button>
          </section>
        </section>
        <section className="flex gap-xl pt-[40px] m:hidden">
          <FooterSocialIconGroup />
        </section>
      </section>
      <section className="flex flex-col-reverse gap-s m:justify-between l:flex-row">
        <p className="mb-[6px] whitespace-nowrap text-body2Light text-neutral-200 l:mb-0">
          © {currentYear} EV.com Inc. All rights reserved
        </p>
        <nav className="flex flex-col m:flex-row m:gap-l">
          {footerLinks.map(({ text, href, analyticsEvent }) => (
            <Link
              className="!px-0 text-body2Light text-neutral-200 hover:text-brandElectric max-lg:mb-[4px]"
              key={text}
              href={href}
              variant={LinkVariants.Default}
              analyticsEvent={analyticsEvent}
            >
              {text}
            </Link>
          ))}
        </nav>
      </section>
      {/* Keep using legacy here for a temp solution, would update this in a separate PR */}
      <LegacyTextButton
        className="absolute bottom-full right-0 flex translate-y-2/4 items-center justify-center border border-sleetGrey-70 bg-white p-[10px]"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
        ariaLabel="Back to top"
      >
        <ChevronUpIcon stroke="#516670" width={24} height={24} />
      </LegacyTextButton>
    </footer>
  );
};

export default Footer;
