import { withSSRContext } from 'aws-amplify';
import { GetServerSidePropsContext } from 'next';
import { AUTH_SCHEME } from './constants';
import { MinimalUser, UserPreview } from './user';

export interface CustomCognitoUserAttributes {
  sub: string;
  email: string;
  phone_number: string;
  /** Is the user's first name and last name or their first name by default. */
  given_name?: string;
  /** The user's first name. */
  name?: string;
  family_name?: string;
  identities: { providerName: string }[] | null;
  picture?: string;
}

export const isKeyOfCustomUserAttribute = (
  key: string
): key is keyof CustomCognitoUserAttributes =>
  [
    'sub',
    'email',
    'phone_number',
    'given_name',
    'name',
    'family_name',
    'identities',
    'picture',
  ].includes(key);

export const mapCognitoAttributesToMinimalUser = (
  attributes: CustomCognitoUserAttributes
): MinimalUser => {
  const defaultUser: MinimalUser = {
    email: '',
    phone: null,
    evId: '',
    lastName: null,
    fullName: '',
    firstName: '',
    identityProvider: null,
  };
  if (!attributes) {
    return defaultUser;
  }
  const {
    email,
    phone_number: phone,
    family_name: familyName,
    name,
    given_name: givenName,
    identities,
    picture,
  } = attributes;
  const defaultName = givenName || name || '';
  return {
    email,
    phone: phone || null,
    evId: email,
    lastName: familyName || null,
    fullName: defaultName,
    firstName: defaultName,
    identityProvider: identities?.[0]?.providerName || null,
    picture: picture || '',
  } satisfies MinimalUser;
};

export const getServerSideAuthHeader = async (
  context: GetServerSidePropsContext
): Promise<string | undefined> => {
  try {
    const { Auth } = withSSRContext(context);
    const user = await Auth.currentAuthenticatedUser();
    const jwt = user.signInUserSession.idToken.jwtToken;

    return `${AUTH_SCHEME} ${jwt}`;
  } catch (error) {
    return undefined;
  }
};

export const fetchUserInfo = async (
  context: GetServerSidePropsContext
): Promise<UserPreview | null> => {
  try {
    const Authorization = await getServerSideAuthHeader(context);

    const response = await fetch(`${process.env.API_ENDPOINT}/api/user/info/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(!!Authorization && { Authorization }),
      },
    });

    if (response.ok) {
      const {
        customer_email: email,
        full_name: fullName,
        phone,
        ev_id: evId,
      }: {
        customer_email: string;
        full_name: string;
        phone?: string;
        ev_id: string;
      } = await response.json();

      return { email, fullName, phone: phone || null, evId };
    }
    return null;
  } catch (error) {
    return null;
  }
};
