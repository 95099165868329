import { useUserZip } from '@/hooks/useUserZip';
import {
  CustomCognitoUserAttributes,
  isKeyOfCustomUserAttribute,
  mapCognitoAttributesToMinimalUser,
} from '@/lib/auth';
import { AUTH_SCHEME, GOOGLE_TRACK_INFO } from '@/lib/constants';
import { handleActionTracking } from '@/lib/handleActionTracking';
import { MinimalCognitoUser, MinimalUser } from '@/lib/user';
import { BasePageProps } from '@/types/page';
import { Auth } from '@aws-amplify/auth';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Hub } from 'aws-amplify';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface AuthProviderProps {
  user: BasePageProps['user'];
}

export interface AuthContextType {
  user: MinimalUser | null;
  loading: boolean;
  setUserAttribute: (key: string, value: string) => void;
  updateUserAttributes: () => Promise<void>;
  signOut: () => Promise<void>;
}

interface RawGetUserSyncResponse {
  first_name?: string;
  last_name?: string;
}

type HubCapsule = {
  channel: string;
  payload: HubPayload;
  source: string;
  patternInfo?: string[];
};
type HubPayload = {
  event: string;
  data?: MinimalCognitoUser;
  message?: string;
};
// TODO: MVB-1103, this type won't be necessary when we upgrade to Amplify v6, since it will have the types we need.
type HubCallback = (capsule: HubCapsule) => void;

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({
  user: userFromServer = null,
  children,
}) => {
  const [user, setUser] = useState<MinimalUser | null>(userFromServer);
  const [loading, setLoading] = useState<boolean>(true);
  const [userAttributes, setUserAttributes] = useState<CognitoUserAttribute[]>(
    []
  );
  const { zip } = useUserZip();

  const setUserAttribute = useCallback(
    (key: string, value: string) => {
      const attribute = userAttributes.find((attr) => attr.getName() === key);
      if (attribute) {
        attribute.setValue(value);
      } else {
        userAttributes.push(
          new CognitoUserAttribute({ Name: key, Value: value })
        );
      }
      setUserAttributes([...userAttributes]);
    },
    [userAttributes]
  );

  const updateUserAttributes = useCallback(async () => {
    const allowedAttributes = ['given_name', 'family_name'];
    const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
    currentUser.updateAttributes(
      userAttributes.filter(({ Name }) => allowedAttributes.includes(Name)),
      () => {
        // Do Nothing
      }
    );
  }, [userAttributes]);

  // one source of truth for the names, this syncs cognito with DB
  const syncNames = useCallback(async () => {
    const userInfo = await Auth.currentAuthenticatedUser();
    const jwt = userInfo.signInUserSession.idToken.jwtToken;
    const response = await fetch(`/api/user/info/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${AUTH_SCHEME} ${jwt}`,
      },
    });
    if (response.ok) {
      const {
        first_name: firstName,
        last_name: lastName,
      }: RawGetUserSyncResponse = await response.json();
      if (firstName) {
        setUserAttribute('given_name', firstName);
      }
      if (lastName) {
        setUserAttribute('family_name', lastName);
      }
      await updateUserAttributes();
    }
  }, [setUserAttribute, updateUserAttributes]);

  const signOut = useCallback(async () => {
    await Auth.signOut();
  }, []);

  const checkAndUpdateUser = async () => {
    setLoading(true);
    try {
      const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
      const currentUserAttributes: CognitoUserAttribute[] =
        await Auth.userAttributes(currentUser);

      const userAttributeRecord = currentUserAttributes.reduce(
        (acc, attribute) => {
          const name = attribute.getName();
          if (isKeyOfCustomUserAttribute(name)) {
            return {
              ...acc,
              [attribute.getName()]:
                name === 'identities'
                  ? JSON.parse(attribute.getValue())
                  : attribute.getValue(),
            };
          }
          return acc;
        },
        {} as CustomCognitoUserAttributes
      );
      setUserAttributes(currentUserAttributes);
      setUser(mapCognitoAttributesToMinimalUser(userAttributeRecord));
    } catch (error) {
      setUser(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    checkAndUpdateUser();
  }, []);

  useEffect(() => {
    const handleFocus = () => {
      checkAndUpdateUser();
    };

    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  // use HUB to listen auth events
  useEffect(() => {
    const listener: HubCallback = async (data) => {
      switch (data.payload.event) {
        case 'signIn':
          if (data.payload.data) {
            setUser(
              mapCognitoAttributesToMinimalUser(data.payload.data.attributes)
            );
            await syncNames();
          }
          handleActionTracking({
            ...GOOGLE_TRACK_INFO.loginSuccess,
            username: data.payload?.data?.username,
            zip,
          });
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
          handleActionTracking({ ...GOOGLE_TRACK_INFO.loginFail, zip });
          break;
        default:
          break;
      }
    };
    return Hub.listen('auth', listener);
  }, [zip, syncNames]);

  const value = useMemo(
    () => ({
      user,
      loading,
      setUserAttribute,
      updateUserAttributes,
      signOut,
    }),
    [user, loading, setUserAttribute, updateUserAttributes, signOut]
  );

  return (
    <AuthContext.Provider value={value}> {children} </AuthContext.Provider>
  );
};
