import { cloudfrontLoader } from '@/image-loaders/cloudfront';
import { ScComponent } from '@/types/interfaces';
import dynamic from 'next/dynamic';
import type { ImageProps as NextImageProps } from 'next/image';
import NextImage from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import LottieAnimation from './lottie-animation.json';

const FallbackImage = '/images/ImageComingSoon.png';

const LottiePlayerLight = dynamic(
  () => import('react-lottie-player/dist/LottiePlayerLight'),
  {
    ssr: false,
  }
);

const CSRImage = dynamic(() => import('./Img'), { ssr: false });

export interface ImageProps extends ScComponent, NextImageProps {
  csr?: boolean;
  disableLoadingGif?: boolean;
  onError?: () => void;
  isAboveFold?: boolean;
  dynamicClassName?: string;
}

const csrList: string[] = ['buildfoc.ford.com'];

const cfDomains = ['ev-dms-images.s3.amazonaws.com'];

const Image: React.FC<ImageProps> = ({
  csr = false,
  className,
  dynamicClassName = 'relative',
  src,
  disableLoadingGif = false,
  onError,
  priority,
  ...otherProps
}) => {
  const [error, setError] = useState<boolean>(false);
  const imgRef = useRef<HTMLDivElement>(null);
  const newClassName =
    !error && src ? className : `${className} bg-background-light rounded`;

  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleImageError = () => {
    if (onError) {
      onError();
    }
    setError(true);
    setLoaded(false);
    setImageSrc(FallbackImage);
  };

  useEffect(() => {
    setError(false);
  }, [src]);

  useEffect(() => {
    if (!imgRef.current) return;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setImageSrc(src as string);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(imgRef.current);

    // eslint-disable-next-line consistent-return
    return () => {
      if (observer && observer.disconnect) {
        observer.disconnect();
      }
    };
  }, [imageSrc, src]);

  // Force client side rendering for certain domains
  if (
    csr ||
    (typeof src === 'string' && csrList.some((item) => src.includes(item)))
  ) {
    return <CSRImage {...otherProps} />;
  }

  if (error || !src) {
    return (
      <NextImage
        className={newClassName}
        {...otherProps}
        src={FallbackImage}
        alt={'image placeholder'}
      />
    );
  }

  return (
    <div className={`${dynamicClassName} h-full w-full`} ref={imgRef}>
      <div
        className={`${!loaded && !disableLoadingGif ? 'flex' : 'hidden'} absolute inset-0 items-center justify-center bg-transparent`}
      >
        <LottiePlayerLight
          animationData={LottieAnimation}
          speed={1}
          style={{
            backgroundColor: 'transparent',
          }}
          direction={1}
          loop
          play
        />
      </div>
      {imageSrc && (
        <NextImage
          className={newClassName}
          {...otherProps}
          loader={
            cfDomains.find((d) => imageSrc.startsWith(`https://${d}`))
              ? cloudfrontLoader
              : undefined
          }
          src={imageSrc}
          onError={handleImageError}
          onLoad={(e) => {
            if (otherProps.onLoad) {
              otherProps.onLoad(e);
            }
            setLoaded(true);
          }}
          priority={priority}
        />
      )}
    </div>
  );
};

export default Image;
